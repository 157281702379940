<template>
    <el-container>
        <el-header height="140px" style="padding:5px 0 0 10px">
            <el-tabs v-model="queryParam.Status" @tab-click="getList()">
                <el-tab-pane label="未填快递" name="0"></el-tab-pane>
                <el-tab-pane label="已填快递" name="1"></el-tab-pane>
            </el-tabs>
            <el-form :inline="true" v-model="queryParam">
                <el-form-item>
                    <el-select class="timeType" v-model="queryParam.SearchField" placeholder="时间类型">
                        <el-option label="创建时间" value="createtime" />
                        <el-option label="付款时间" value="paytime" />
                        <el-option label="快递时间" value="expresstime" />
                    </el-select>
                    <el-date-picker v-model="daterange" type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                        :clearable="true" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="采购商：">
                    <el-input v-model.trim="queryParam.Purchaser"></el-input>
                </el-form-item>
                <el-form-item label="付款状态：">
                    <el-select v-model="queryParam.PayStatus" placeholder="是否付款">
                        <el-option label="未付款" value="0" />
                        <el-option label="已付款" value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="收件信息：">
                    <el-input placeholder="请输入收件信息" class="receiver" v-model.trim="queryParam.Receiver"></el-input>
                </el-form-item>
                <el-form-item v-if="$store.state.routes.systemSetting.MultiWarehouse" label="发货仓库：">
                    <warehouse-select :value.sync="queryParam.WID" select_style="width:150px;"></warehouse-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList()">查询</el-button>
                    <el-button type="primary" plain @click="resetQuery">清空</el-button>
                </el-form-item>
            </el-form>
            <div class="operate">
                <el-button type="success" @click="modifyWarehouse"
                    v-if="$store.state.routes.systemSetting.MultiWarehouse && queryParam.Status == 0"
                    plain>修改发货仓库</el-button>
                <el-popover placement="top-start" width="220" trigger="click" title="选择导出内容：">
                    <el-button type="text" @click="exportTrade()">导出全部信息</el-button>
                    <el-button type="text" @click="exportTrade('express')">导出快递信息</el-button>
                    <el-button type="primary" plain slot="reference">导出订单</el-button>
                </el-popover>
                <el-upload ref="uploadXls" accept=".xls,.xlxs" :action="xlsData.uploadUrl" :show-file-list="false"
                    :headers="xlsData.headers" :on-success="uploadXlsSuccess" :multiple="false"
                    :on-error="uploadXlsError" v-if="queryParam.Status == 0">
                    <el-button type="warning" plain>导入快递</el-button>
                </el-upload>
                <el-button @click="print">{{ queryParam.Status == 0 ? '获取' : '' }}面单打印</el-button>
                <customer-parameter v-if="queryParam.Status == 0" ref="parameter" label="填写物流QQ自动通知"
                    param-key="ProxyEditExpressNotice" type="switch" :value.sync="expressNotice" />
            </div>
        </el-header>
        <el-main>
            <el-table stripe border ref="proxyTrade" :key="tradeKey" row-key="TradeID" :data="tableData.trades"
                v-loading="tableData.loading" @selection-change="selectTrade" height="100%"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
                <el-table-column type="selection" width="39" />
                <el-table-column prop="Tid" label="订单号" show-overflow-tooltip />
                <el-table-column prop="WName" v-if="$store.state.routes.systemSetting.MultiWarehouse" label="仓库"
                    width="120" />
                <el-table-column prop="PurchaserName" label="采购商" width="170" />
                <el-table-column label="买家信息" width="170">
                    <template slot-scope="{ row }">
                        <span>{{ row.Proxy.ReceiverName + '，' + row.Proxy.ReceiverMobile }}</span><br />
                        <span>{{ row.Proxy.ReceiverProvince + ' ' + row.Proxy.ReceiverCity + ' ' +
                            row.Proxy.ReceiverDistrict + ' ' + row.Proxy.ReceiverAddress }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Details" label="商品信息" width="280" key="Details">
                    <template slot-scope="{ row }">
                        <div :class="!row.showAll ? 'expansion' : ''">
                            <span v-for="(detail, index) in row.Details" :key="index">
                                {{ detail.ItemNo + "，" + detail.Color + "，" + detail.Size + "*" + detail.Num }}<br />
                            </span>
                        </div>
                        <el-button type="text" v-if="row.Details.length > 3" @click="changeShow(row)">
                            {{ row.showAll ? '收起' : '展开' }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="Payment" label="金额">
                    <template slot-scope="{ row }">
                        商品：￥{{ row.Payment }}<br />
                        运费：￥{{ row.PostFee }}<br />
                        应付：￥{{ row.Payment + row.PostFee | decimalFormat }}<br />
                        实付：￥{{ row.ReceivedPayment }}
                    </template>
                </el-table-column>
                <el-table-column prop="Logistics" label="快递信息">
                    <template slot-scope="{ row }">
                        <el-button v-if="row.Proxy.ExpressStatus == 0" type="primary" plain slot="reference"
                            @click="showExpressDlg(row)">填写快递</el-button>
                        <span v-else>{{ row.Proxy.ExpressName }}<br />{{ row.Proxy.ExpressNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="PayType" label="付款">
                    <template slot-scope="{ row }">
                        <span v-if="row.PayType == 'cash'">现金</span>
                        <span v-else-if="row.PayType == 'wechat'">微信</span>
                        <span v-else-if="row.PayType == 'alipay'">支付宝</span>
                        <span v-else>欠款</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Remark" label="备注" />
                <el-table-column prop="Time" label="时间" width="200">
                    <template slot-scope="{ row }">
                        <span>创建：{{ row.CreateTime }}</span><br />
                        <span v-if="row.PayType != 'credit'">付款：{{ row.PayTime }}</span><br />
                        <span v-if="queryParam.Status == 1">快递：{{ row.Proxy.ExpressTime }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog v-dialog-drag title="填写快递信息" width="300px" :visible="expressDlg.visible"
                :close-on-click-modal="false" @close="expressDlg.visible = false">
                <el-form label-width="100px">
                    <el-form-item label="快递名称：">
                        <el-autocomplete v-model="expressDlg.expressName" :fetch-suggestions="queryExpressSearch"
                            placeholder="请输入快递名称" @select="handleExpressSelect" style="width: 150px;"
                            clearable></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="快递单号：">
                        <el-input v-model.trim="expressDlg.expressNo" clearable style="width: 150px;"
                            placeholder="请输入快递单号"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="expressDlg.visible = false">取消</el-button>
                    <el-button type="primary" @click="handleEditExpress()">保存</el-button>
                </div>
            </el-dialog>
            <express-config ref="expressConfig" :status="queryParam.Status" @refresh="getList"></express-config>
            <el-dialog v-dialog-drag title="修改发货仓库" width="400px" :visible="editWarehouseDlg.visible"
                :close-on-click-modal="false" @close="editWarehouseDlg.visible = false">
                <el-form label-width="100px">
                    <el-form-item label="发货仓库：" prop="WID">
                        <warehouse-select ref="editWarehouse" :value.sync="editWarehouseDlg.form.WID"
                            :set_default="true"></warehouse-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="editWarehouseDlg.visible = false">关闭</el-button>
                    <el-button type="primary" @click="handleEditWarehouse"
                        :loading="editWarehouseDlg.loading">保存</el-button>
                </div>
            </el-dialog>
        </el-main>
        <el-footer height="40px">
            <el-pagination background :total="tableData.total" :page-size.sync="queryParam.PageSize"
                :current-page.sync="queryParam.CurrentPage" :page-sizes="[15, 30, 50, 100, 200]"
                layout="prev, pager, next,total, sizes" @size-change="getList()" @current-change="getList(false)"
                :hide-on-single-page="true" />
        </el-footer>
    </el-container>
</template>

<script>
import { submit, exportFile } from '@/api/base';
import dayjs from 'dayjs';
import { baseURL, successCode } from "@/config";
import CustomerParameter from "@/views/components/common/CustomerParameter.vue";
import ExpressConfig from "./components/expressConfig.vue";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";

export default {
    name: 'stallProxyTrade',
    components: {
        CustomerParameter,
        ExpressConfig,
        WarehouseSelect,
    },
    mounted() {
        this.$nextTick(async () => {
            await this.getList();
            await this.$refs.parameter.get();
        });
    },
    data() {
        return {
            loading: false,
            statusTab: 'proxyTrade',
            autoPolling: true,
            expressNotice: false,
            newTradeCount: 0,
            daterange: '',
            queryParam: {
                SearchField: 'createtime',
                PageSize: 15,
                CurrentPage: 1,
                StartTime: '',
                EndTime: '',
                Purchaser: '',
                Receiver: '',
                Status: 0,
                PayStatus: null,
                WID: 0,
            },
            tableData: {
                loading: false,
                trades: [],
                total: 0,
                totalCount: 0,
                totoalAmount: 0.0
            },
            tradeKey: '',
            xlsData: {
                loading: false,
                uploadUrl: `${baseURL}/api/stall/proxy/importExpress`,
                headers: {
                    Authorization: "Bearer " + this.$store.getters["user/accessToken"],
                },
            },
            expressDlg: {
                visible: false,
                expresses: [
                    { value: '圆通速递' },
                    { value: '申通快递' },
                    { value: '中通快递' },
                    { value: '韵达速递' },
                    { value: '极兔速递' },
                    { value: '顺丰速运' },
                    { value: '邮政包裹' },
                    { value: '京东快递' },
                    { value: '德邦快递' },
                    { value: '丰网速运' },
                    { value: '安能物流' },
                    { value: '天天快递' }
                ],
                tradeID: '',
                expressNo: '',
                expressName: ''
            },
            editWarehouseDlg: {
                visible: false,
                loading: false,
                form: {
                    TradeIDs: [],
                    WID: undefined,
                    WName: '',
                }
            },
            selectForm: {
                totalPayment: 0
            },
        };
    },
    methods: {
        modifyWarehouse() {
            const selection = JSON.parse(JSON.stringify(this.$refs.proxyTrade.selection));
            if (selection.length == 0) {
                this.$message({ type: "error", message: "请选择订单" });
                return;
            }
            this.editWarehouseDlg.form = {
                TradeIDs: selection.map(t => t.TradeID),
                WID: selection[0].WID
            };
            this.editWarehouseDlg.loading = false;
            this.editWarehouseDlg.visible = true;
        },
        handleEditWarehouse() {
            const that = this;
            if (typeof (that.editWarehouseDlg.form.WID) == 'undefined' || that.editWarehouseDlg.form.WID <= 0) {
                that.$message({ type: 'error', message: '发货仓库不能为空' });
                return false;
            }
            let wname = that.$refs.editWarehouse.$refs.warehouse.selectedLabel;
            that.$baseConfirm('确定将选中的 ' + that.editWarehouseDlg.form.TradeIDs.length + ' 个订单的发货仓库修改为【' + wname + '】吗?', null, async () => {
                that.editWarehouseDlg.loading = true;
                await submit('/api/stall/proxy/editWarehouse', that.editWarehouseDlg.form);
                that.editWarehouseDlg.loading = false;
                that.editWarehouseDlg.visible = false;
                that.$message({ type: "success", message: "保存成功" });
                that.getList();
            });
        },
        async getList(reload = true) {
            if (reload) {
                this.queryParam.CurrentPage = 1;
            }
            this.queryParam.StartTime = this.daterange && this.daterange.length > 0 ? this.daterange[0] : null;
            this.queryParam.EndTime = this.daterange && this.daterange.length > 1 ? this.daterange[1] : null;
            const { data } = await submit('/api/stall/proxy/getList', this.queryParam);
            this.tableData.total = data.total;
            this.tableData.trades = data.datas;
            this.tableData.trades.forEach(trade => {
                if (trade.Details.length > 3) {
                    trade.showAll = false;
                }
            });
        },
        resetQuery() {
            this.daterange = '';
            this.queryParam.SearchField = 'createtime';
            this.queryParam.StartTime = '';
            this.queryParam.EndTime = '';
            this.queryParam.Purchaser = '';
            this.queryParam.Receiver = '';
            this.queryParam.PayStatus = null;
            this.getList();
        },
        async exportTrade(type = 'all') {
            let tradeIds = '';
            const selection = JSON.parse(JSON.stringify(this.$refs.proxyTrade.selection));
            if (selection.length > 0) {
                tradeIds = selection.map(p => { return p.TradeID });
            }
            this.loading = true;
            const { data } = await exportFile('/api/stall/proxy/export', {
                Status: this.queryParam.Status,
                Type: type,
                TradeIDs: tradeIds
            }).finally(() => {
                this.loading = false;
            });

            let date = '';
            if (this.queryParam.StartTime) {
                date = dayjs(this.queryParam.StartTime).format('YYYY-MM-DD');
            }
            if (this.queryParam.EndTime) {
                date += (date ? '至' : '') + dayjs(this.queryParam.EndTime).format('YYYY-MM-DD');
            }
            const fileName = type == 'all' ? `${date}订单表` : `${date}订单快递表`;

            const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        uploadXlsSuccess(res) {
            if (res.msg) {
                this.$message({ type: "error", message: "导入错误：" + res.msg });
            } else {
                this.$message({ type: "success", message: "导入成功，" + res.data });
            }
            this.$refs.uploadXls.clearFiles();
            this.xlsData.loading = false;
            this.getList();
        },
        uploadXlsError() {
            this.$message({ type: "error", message: "导入失败" });
            this.xlsData.loading = false;
        },
        changeShow(row) {
            row.showAll = !row.showAll;
            this.tradeKey = Math.random();
        },
        showExpressDlg(row) {
            this.expressDlg.tradeID = row.TradeID;
            this.expressDlg.expressName = '';
            this.expressDlg.expressNo = '';
            this.expressDlg.visible = true;
        },
        queryExpressSearch(queryString, cb) {
            var results = queryString ? this.expressDlg.expresses.filter(t => t.value.includes(queryString)) : this.expressDlg.expresses;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        handleExpressSelect(row) {
            this.expressDlg.expressName = row.value;
        },
        async handleEditExpress() {
            if (!this.expressDlg.tradeID) {
                return;
            }
            if (!this.expressDlg.expressNo) {
                this.$message({ type: "error", message: "请输入快递单号" });
                return;
            }
            await submit('/api/stall/proxy/editExpress', {
                TradeID: this.expressDlg.tradeID,
                ExpressName: this.expressDlg.expressName,
                ExpressNo: this.expressDlg.expressNo
            });
            this.expressDlg.visible = false;
            this.$message({ type: "success", message: "保存成功" });
            this.getList();
        },
        selectTrade(selection) {
            this.selectForm.totalPayment = 0;
            selection.forEach(s => {
                if (s.PayStatus < 2) {
                    this.selectForm.totalPayment += s.Payment + s.PostFee;
                }
            });
        },
        async print() {
            const selection = JSON.parse(JSON.stringify(this.$refs.proxyTrade.selection));
            if (selection.length == 0) {
                this.$message({ type: "error", message: "请选择订单" });
                return;
            }
            let tradeIDs = selection.map(t => t.TradeID);
            this.$refs.expressConfig.showConfig(tradeIDs);
        },

    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-header {
        .receiver {
            .el-input__inner {
                width: 190px !important;
            }
        }

        .timeType {
            margin-right: 2px;
            height: 35px;
            vertical-align: middle;

            .el-input__inner {
                width: 100px !important;
            }
        }

        .el-input {
            .el-input__inner {
                width: 150px;
            }
        }

        .operate {
            margin-bottom: 10px;

            &>div {
                display: inline-block;
            }

            .el-button,
            .el-checkbox {
                margin-right: 10px;
                margin-left: 0px;
            }
        }

    }

    .el-main {

        .el-button {
            margin-right: 5px;
            display: inline-block;
            padding: 9px;
        }

        .expansion {
            white-space: pre-line;
            width: 10.3rem;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
}
</style>
