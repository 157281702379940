<template>
    <el-dialog v-dialog-drag title="打印配置" width="450px" :visible="visible" :close-on-click-modal="false"
        @close="visible = false">
        <el-form label-width="100px" ref="express" label-suffix="：" :model="value" :rules="rules">
            <template v-if="status == 0">
                <el-form-item label="快递平台" prop="platform">
                    <el-select v-model="value.platform" value-key="Name" @change="changePlatform"
                        no-data-text="无支持的快递平台">
                        <el-option v-for="item in options.platform" :key="'platform' + item.ID" :label="item.Name"
                            :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物流账号" prop="account">
                    <el-select v-model="value.account" value-key="ExpressName" @change="getAccountConfig"
                        no-data-text="未找到该平台对应的物流账号！">
                        <el-option v-for="item in accounts" :key="'account' + item.ID" :label="item.ExpressName"
                            :value="item">
                        </el-option>
                    </el-select>
                    <el-link style="margin-left: 10px;" type="info" icon="el-icon-setting"
                        @click="$router.push({ path: '/express/account' })" :underline="false">配置</el-link>
                </el-form-item>
                <el-form-item label="快递" prop="logistics">
                    <el-select v-model="value.logistics" value-key="CompanyName" @change="getAccountConfig"
                        no-data-text="无支持的快递">
                        <el-option v-for="(item, index) in options.logistics" :key="'logistics' + index"
                            :label="item.CompanyName" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="网点" prop="branch">
                    <el-select v-model="value.branch" value-key="BranchName" popper-class="branch"
                        no-data-text="未找到该快递对应的网点！">
                        <el-option v-for="(item, index) in options.branch" :key="'branch' + index"
                            :label="item.BranchName" :value="item">
                            <div>
                                <el-row :gutter="5">
                                    <el-col :span="14" class="title">
                                        {{ item.BranchName }}
                                    </el-col>
                                    <el-col :span="10" class="quantity">
                                        余额：<span :style="{ color: item.Quantity == 0 ? 'red' : '' }">
                                            {{ item.Quantity }}</span>
                                    </el-col>
                                </el-row>
                                <div class="address">地址：{{ item.address.Province }} {{ item.address.City }} {{
        item.address.District }} {{ item.address.Address }}</div>
                            </div>
                        </el-option>
                    </el-select>
                    <el-link style="margin-left: 10px;" type="info" icon="el-icon-setting" target="_blank"
                        :underline="false"
                        href="https://miandan.cainiao.com/miandan/subscription/subscription.htm">配置</el-link>
                </el-form-item>
                <el-form-item label="打印模板" prop="template">
                    <el-select placeholder="请选择打印模板" value-key="UserStdTemplateName" v-model="value.template"
                        no-data-text="未找到该快递对应的模板！">
                        <el-option v-for="( item, index ) in options.template" :key="'template' + index"
                            :label="item.UserStdTemplateName" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
            <el-form-item v-if="socketError && value.platform">
                <span class="error">
                    {{ value.platform.Name }}打印组件异常，请检查组件
                    <a v-if="value.platform.SocketConfig && value.platform.SocketConfig.download"
                        style="margin-left:10px" :href="value.platform.SocketConfig.download">下载组件</a>
                </span>
            </el-form-item>
            <el-form-item label="打印机" prop="printer">
                <el-select placeholder="请选择打印机" value-key="name" v-model="value.printer">
                    <el-option v-for="( item, index ) in options.printer" :key="'printer' + index" :value="item"
                        :label="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
            <el-button type="primary" size="small" :loading="loading" @click="doPrint">
                {{ status == 0 ? '获取面单' : '' }}打印</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
export default {
    name: 'express',
    props: {
        //状态
        status: {},
    },
    data() {
        return {
            socketError: false,
            loading: false,
            value: {
                platform: null,
                account: null,
                printer: null,
                logistics: null,
                branch: null,
                template: null,
            },
            options: {
                platform: [],
                account: [],
                printer: [],
                logistics: [],
                branch: [],
                template: [],
            },
            rules: {
                account: [
                    { required: true, message: '请先配置物流账号！', trigger: 'change' }
                ],
                logistics: [
                    { required: true, message: '请先配置打印快递！', trigger: 'change' }
                ],
                branch: [
                    { required: true, message: '请先选择网点！', trigger: 'change' }
                ],
                template: [
                    { required: true, message: '请先选择打印模板！', trigger: 'change' }
                ],
                printer: [
                    { required: true, message: '请先选择打印机！', trigger: 'change' }
                ]
            },
            tradeIDs: [],
            visible: false
        }
    },
    beforeDestroy() {
        if (this.options.platform) {
            this.options.platform.forEach(item => {
                if (item.socket && item.socket.readyState == 1) {
                    item.socket.close();
                }
            });
        }
    },
    computed: {
        accounts() {
            if (this.options.account) {
                return this.options.account.filter(t => t.PlatformID == this.value.platform.ID);
            }
            return [];
        },
    },
    async mounted() {
        await this.getExpressConfig();
    },
    methods: {
        //#region
        async getExpressConfig() {
            const { data } = await submit("/api/express/GetExpressConfig");
            this.value = { ...this.value, ...data.value };
            this.options = { ...this.options, ...data.options };
            this.changePlatform();
        },
        changePlatform() {
            if (this.accounts.length > 0) {
                let defaultAccount = this.accounts.filter(t => t.IsDefault);
                if (defaultAccount.length > 0) {
                    this.value.account = defaultAccount[0];
                }
                else {
                    this.value.account = this.accounts[0];
                }
                this.getAccountConfig();
            }
            this.initWebSocket();
        },
        async getAccountConfig() {
            this.value.branch = null;
            this.value.template = null;
            this.options.branch = [];
            this.options.template = [];
            if (!this.value.account || !this.value.logistics) {
                return;
            }
            const { data } = await submit("/api/express/GetAccountConfig", {
                accountID: this.value.account.ID,
                cpCode: this.value.logistics.CompanyCode
            });

            data.branch.forEach(branch => {
                if (!branch.BranchName) {
                    branch.BranchName = this.value.logistics.CompanyName;
                }
                branch.ShippAddressCols.forEach(address => {
                    this.options.branch.push({ ...branch, address: address });
                });
            });
            this.value.branch = this.options.branch.length > 0 ? this.options.branch[0] : null;
            this.value.template = data.template.length > 0 ? data.template[0] : null;
            this.options.template = data.template;
        },
        changeLogistics() {
            this.getAccountConfig();
        },
        async getWaybillCodes() {
            const { data } = await submit("/api/express/getWaybills", {
                AccountID: this.value.account.ID,
                CpCode: this.value.logistics.CompanyCode,
                CpName: this.value.logistics.CompanyName,
                BranchCode: this.value.branch.BranchCode,
                BrandCode: this.value.branch.BrandCode,
                TemplateID: this.value.template.UserStdTemplateId,
                TemplateUrl: this.value.template.UserStdTemplateUrl,
                ShippAddress: this.value.branch.address,
                TradeIDs: this.tradeIDs,
                Source: 'stall'
            });
            if (!data.success) {
                throw data.msg;
            }
        },
        async getWaybillData() {
            const { data } = await submit("/api/express/getWaybillData", {
                tradeIDs: this.tradeIDs,
                Source: 'stall'
            });
            if (data.success) {
                return data.result;
            }
            else {
                throw data.msg;
            }
        },
        //#endregion
        //#region 创建websocket
        initWebSocket() {
            if (!this.value.platform) {
                return;
            }
            let item = this.value.platform;
            if (item.socket && item.socket.readyState == 1) {
                return;
            }
            // 实例化socket，这里的实例化直接赋值给item.socket; 完成通信后关闭WebSocket连接
            item.socket = new WebSocket(item.SocketConfig.url);
            let that = this;
            item.socket.onopen = () => {
                // console.log(item.Name + '打印组件连接成功');
                that.getPrinters();
                that.socketError = false;
            }
            item.socket.onmessage = (event) => {
                // console.log('接收' + item.Name + '打印组件消息');
                let methods = that.$options.methods;
                methods[`${item.Code}OnMessage`](that, event.data);
            }
            item.socket.onerror = (event) => {
                // console.log(item.Name + '打印组件连接异常', event);
                that.socketError = true;
            }
            item.socket.onclose = (event) => {
                // console.log(item.Name + '打印组件连接关闭', event);
                that.socketError = true;
            }
        },
        //#endregion
        //#region 菜鸟相关
        cainiaoOnMessage(that, data) {
            var response = JSON.parse(data);
            if (response.cmd == 'getPrinters') {
                that.options.printer = response.printers;
                that.value.printer = response.printers.filter(t => t.name == response.defaultPrinter)[0];
            }
            else if (response.cmd == "print") {
                console.log(response);
                that.loading = false;
                if (response.status == "success") {
                    that.$message({ type: "success", message: "提交打印任务成功" });
                }
                else {
                    that.$message({ type: "error", message: response.msg });
                }
            }
            else if (response.cmd == "notifyTaskResult" && response.status == "completeSuccess") {
                that.$message({ type: "success", message: "打印成功" });
                if (that.status == 0) {
                    that.$emit('refresh');
                }
                that.visible = false;
            }
        },
        getUUID(len, radix) {
            var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            var uuid = [], i;
            radix = radix || chars.length;
            if (len) {
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                var r;
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }
            return uuid.join('');
        },
        cainiaoDoPrint(that, cmd, results) {
            var request = new Object();
            request.requestID = that.getUUID(8, 16);
            request.version = "1.0";
            request.cmd = cmd;
            request.task = new Object();
            request.task.taskID = that.getUUID(8, 10);
            request.task.preview = false;
            request.task.printer = that.value.printer.name;
            request.task.documents = new Array();
            let waybillCodes = Object.keys(results);
            for (let i = 0; i < waybillCodes.length; i++) {
                var doc = new Object();
                doc.documentID = waybillCodes[i];
                doc.contents = results[waybillCodes[i]];
                request.task.documents.push(doc);
            }
            return request;
        },
        cainiaoGetPrinters(that, cmd) {
            var request = new Object();
            request.requestID = that.getUUID(8, 16);
            request.version = "1.0";
            request.cmd = cmd;
            return request;
        },
        //#endregion
        //#region 打印相关
        executeRequest(method, obj = null) {
            let platform = this.value.platform;
            if (!platform || !platform.socket || platform.socket.readyState != 1) {
                return;
            }
            if (platform.SocketConfig.methods[method]) {
                let methods = this.$options.methods;
                let request = methods[`${platform.Code}${method.slice(0, 1).toUpperCase()}${method.slice(1)}`](this, platform.SocketConfig.methods[method], obj);
                platform.socket.send(JSON.stringify(request));
            }
        },
        //请求打印机列表
        getPrinters() {
            this.options.printer = [];
            this.value.printer = null;
            this.executeRequest('getPrinters');
        },
        showConfig(tradeIDs) {
            this.tradeIDs = tradeIDs;
            this.visible = true;
        },
        //打印
        async doPrint() {
            this.$refs.express.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    try {
                        if (this.status == 0) {
                            await this.getWaybillCodes();
                        }
                        var results = await this.getWaybillData();
                        this.executeRequest('doPrint', results);
                    }
                    catch (ex) {
                        this.loading = false;
                        this.$message({ type: 'error', message: ex });
                        return;
                    }
                }
            });
        },
        //#endregion
    }
}
</script>
<style lang="scss" scoped>
::v-deep {
    .el-dialog__body {
        padding: 10px;
    }
}

.el-select-dropdown {
    ::v-deep {
        .el-select-dropdown__empty {
            color: red !important;
        }
    }
}

.error {
    color: red;
}

.branch {
    li {
        height: auto !important;
        line-height: 30px;

        .title {
            display: flex;
            height: 32px;
            font-weight: bold;
        }

        .quantity {
            text-align: right;
        }

        .address {
            color: #909399;
            font-size: 12px;
        }
    }

    li+li {
        border-top: 0.5px solid #d8dadf;
    }
}
</style>